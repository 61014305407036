const originalFetch = window.fetch;
import { getSandbox } from "ZustandState/useSanbox";
window.fetch = async (input, init = {}) => {
  // Get token from localStorage, sessionStorage, or state management
  let token = localStorage.getItem("admin_token");
  const apiKey = localStorage.getItem("admin_apiKey");
  const sandbox = getSandbox();
  // Ensure headers exist
  init.headers = {
    ...init.headers,
    Authorization: `Bearer ${token}`,
    apiKey: apiKey,
    "Content-Type": "application/json",
  };

  // ✅ Move credentials OUTSIDE of headers
  init.credentials = "include";

  if (init.body) {
    try {
      let bodyData = JSON.parse(init.body); // Parse body if it's JSON
      if (bodyData.apiKey) {
        delete bodyData.apiKey; // ✅ Remove apiKey
        init.body = JSON.stringify(bodyData); // Convert back to JSON string
      }
    } catch (err) {
      console.error("Error parsing request body:", err);
    }
  }

  try {
    let response = await originalFetch(input, init);
    console.log('resp refresh', response)
    // 🔄 If access is forbidden, try to refresh the token
    if (response.status === 401) {
      console.warn("Access forbidden, refreshing token...");
      let apiUrl = sandbox ? 'https://sandbox.payhub.link' : 'https://api.payhub.link';
      const refreshResponse = await originalFetch(`${apiUrl}/admin/refresh`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          apiKey: apiKey,
        },
      });


      if (refreshResponse.ok) {
        const data = await refreshResponse.json();
        console.log("New Token Received:", data.accessToken);

        // ✅ Store the new token
        localStorage.setItem("admin_token", data.accessToken);

        // ✅ Retry the original request with the new token
        init.headers.Authorization = `Bearer ${data.accessToken}`;
        response = await originalFetch(input, init);
      } else {
        console.error("Failed to refresh token, logging out...");
        localStorage.removeItem("admin_token"); // Clear token
        window.location.href = "/login"; // Redirect to login
        return refreshResponse;
      }
    }

    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
